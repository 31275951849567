// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBnOGDYK_Gv_0hGxHT2BlsnAFUPzvrk5ls",
  authDomain: "indbnew7.firebaseapp.com",
  databaseURL: "https://indbnew7-default-rtdb.firebaseio.com",
  projectId: "indbnew7",
  storageBucket: "indbnew7.appspot.com",
  messagingSenderId: "1005909739975",
  appId: "1:1005909739975:web:d13953348e4b2ce8a2b1e2",
  measurementId: "G-CW4CK92RRZ"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };